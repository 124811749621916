import React from "react"
import { Link } from "gatsby"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import StoreIcon from "@material-ui/icons/Store"
import PaletteIcon from "@material-ui/icons/Palette"
import EditIcon from "@material-ui/icons/Edit"
import PreviewIcon from "@material-ui/icons/Tv"
import ReportsIcon from "@material-ui/icons/GraphicEq"
import AccountIcon from "@material-ui/icons/AccountBox"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import Description from "@material-ui/icons/Description"
import Divider from "@material-ui/core/Divider"
import styled from "styled-components"

import { get } from "lodash"

import { useStateValue } from "../state"

const NavLink = styled(Link)`
  pointer-events: ${props => (props.disabled ? "none" : undefined)};
`

function Sidebar() {
  const [state] = useStateValue()
  // TODO: disable buttons based on if product is loaded
  const productId = get(state, "editProduct.id", "")
  const buttonsDisabled = !productId
  const buttonColor = buttonsDisabled ? "disabled" : undefined

  const navItems = [
    {
      label: "Form Builder",
      icon: <EditIcon color={buttonColor} />,
      to: `/products/${productId}`,
    },
    {
      label: "Preview",
      icon: <PreviewIcon color={buttonColor} />,
      to: "/preview",
    },
    {
      label: "Rating",
      icon: <MonetizationOnIcon color={buttonColor} />,
      to: "/rating",
    },
    {
      label: "Document Builder",
      icon: <Description color={buttonColor} />,
      to: "/pdfMake",
    },
    {
      label: "Reports",
      icon: <ReportsIcon color={buttonColor} />,
      to: "/",
    },
    { label: "Account", icon: <AccountIcon color={buttonColor} />, to: "/" },
  ]

  return (
    <>
      <List>
        <Link key="Products" to="/products">
          <ListItem button>
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary="Products" />
          </ListItem>
        </Link>
        <Link key="Company" to="/company">
          <ListItem button>
            <ListItemIcon>
              <PaletteIcon />
            </ListItemIcon>
            <ListItemText primary="Company" />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        {navItems.map((entry, index) => (
          <NavLink key={entry.label} to={entry.to} disabled={buttonsDisabled}>
            <ListItem button onClick={entry.onClick}>
              <ListItemIcon disabled={buttonsDisabled}>
                {entry.icon}
              </ListItemIcon>
              <ListItemText primary={entry.label} />
            </ListItem>
          </NavLink>
        ))}
      </List>
    </>
  )
}

export default Sidebar
