import { cloneDeep } from "lodash"
import { getNextId } from "../util/pages"

export default function reducer(state, action) {
  switch (action.type) {
    case "update": {
      return {
        ...action.newState,
      }
    }
    case "addTask": {
      const { tasks, columns } = state.editProduct.jsondata
      const { columnId } = action

      const newTasks = { ...tasks }
      const newId = getNextId(tasks, "task")
      newTasks[newId] = {
        id: newId,
        label: `New Task ${newId}`,
        type: "text",
      }

      const newColumns = { ...columns }
      newColumns[columnId].taskIds.push(newId)

      return {
        ...state,
        editProduct: {
          ...state.editProduct,
          jsondata: {
            ...state.editProduct.jsondata,
            tasks: newTasks,
            columns: newColumns,
          },
        },
      }
    }
    case "ADD_FIELD_ARRAY_TASK": {
      const { tasks } = state.editProduct.jsondata
      const { taskId } = action

      const newTasks = { ...tasks }
      const newId = getNextId(tasks, "task")
      newTasks[newId] = {
        id: newId,
        label: `New Task ${newId}`,
        type: "text",
      }

      if (!newTasks[taskId].taskIds) newTasks[taskId].taskIds = []
      newTasks[taskId].taskIds.push(newId)

      return {
        ...state,
        editProduct: {
          ...state.editProduct,
          jsondata: {
            ...state.editProduct.jsondata,
            tasks: newTasks,
          },
        },
      }
    }
    case "addColumn": {
      const { columns, columnOrder } = state.editProduct.jsondata

      const newColumns = { ...columns }
      const newId = getNextId(columns, "column")
      newColumns[newId] = {
        id: newId,
        title: newId,
        taskIds: [],
        template: "question",
      }

      const newColumnOrder = [...columnOrder]
      newColumnOrder.push(newId)

      return {
        ...state,
        editProduct: {
          ...state.editProduct,
          jsondata: {
            ...state.editProduct.jsondata,
            columns: newColumns,
            columnOrder: newColumnOrder,
          },
        },
      }
    }
    case "openModal": {
      const { content } = action

      return {
        ...state,
        // isModalOpen: true,
        modalContent: content,
      }
    }
    case "closeModal": {
      return {
        ...state,
        // isModalOpen: false,
        modalContent: undefined,
      }
    }
    case "deleteColumn": {
      const { columns, columnOrder } = state.editProduct.jsondata
      const { columnId } = action

      const newColumns = { ...columns }
      const newColumnOrder = [...columnOrder]

      delete newColumns[columnId]
      const indexOfColumn = newColumnOrder.indexOf(columnId)
      if (indexOfColumn > -1) {
        newColumnOrder.splice(indexOfColumn, 1)
      }

      return {
        ...state,
        editProduct: {
          ...state.editProduct,
          jsondata: {
            ...state.editProduct.jsondata,
            columns: newColumns,
            columnOrder: newColumnOrder,
          },
        },
      }
    }
    case "updateColumn": {
      const { column } = action
      const { columns } = state.editProduct.jsondata
      const newColumns = { ...columns }
      newColumns[column.name].title = column.title
      newColumns[column.name].template = column.template
      return {
        ...state,
        editProduct: {
          ...state.editProduct,
          jsondata: {
            ...state.editProduct.jsondata,
            columns: newColumns,
          },
        },
      }
    }
    case "deleteTask": {
      const { task } = action
      const { tasks, columns } = state.editProduct.jsondata

      const newTasks = { ...tasks }
      delete newTasks[task]

      let newColumns = cloneDeep(columns)
      
      let foundTask = false
      Object.keys(newColumns).forEach(col => {
        let taskIds = [...newColumns[col].taskIds]
        if (taskIds.includes(task)) {
          const index = taskIds.indexOf(task)
          taskIds.splice(index, 1)
          newColumns[col].taskIds = taskIds
          foundTask = true
        }
      })

      // search through fieldArray subtask lists
      if (!foundTask) {
        Object.keys(newTasks).forEach(t => {
          if (newTasks[t].type === 'fieldArray') {
            let taskIds = [...newTasks[t].taskIds]
            if (taskIds.includes(task)) {
              const index = taskIds.indexOf(task)
              taskIds.splice(index, 1)
              newTasks[t].taskIds = taskIds
            }
          }
        })
      }
      

      return {
        ...state,
        editProduct: {
          ...state.editProduct,
          jsondata: {
            ...state.editProduct.jsondata,
            tasks: newTasks,
            columns: newColumns,
          },
        },
      }
    }
    case "updateTask": {
      const { task } = action
      const { tasks } = state.editProduct.jsondata

      const newTasks = { ...tasks }
      newTasks[task.id] = Object.assign(newTasks[task.id], task)

      return {
        ...state,
        editProduct: {
          ...state.editProduct,
          jsondata: {
            ...state.editProduct.jsondata,
            tasks: newTasks,
          },
        },
      }
    }
    case "startLoading": {
      return {
        ...state,
        isLoading: true,
      }
    }
    case "stopLoading": {
      return {
        ...state,
        isLoading: false,
      }
    }
    case "SET_USER": {
      return {
        ...state,
        user: {
          isLoggedIn: true,
          ...action.user,
        },
      }
    }
    case "LOGOUT_USER": {
      return {
        ...state,
        user: {
          isLoggedIn: false,
        },
      }
    }
    case "SET_PRODUCTS": {
      const { products } = action
      return {
        ...state,
        products,
      }
    }
    case "SET_EDIT_PRODUCT": {
      const { product } = action
      return {
        ...state,
        editProduct: product,
      }
    }
    case "UPDATE_THEME": {
      const { theme } = action
      return {
        ...state,
        user: {
          ...state.user,
          company: {
            ...state.user.company,
            theme
          }
        }
      }
    }
    case "APPEND_PRODUCT": {
      const { product } = action

      return {
        ...state,
        products: [...state.products, product],
      }
    }
    case "SAVE_PRODUCT": {
      return {
        ...state,
      }
    }
    case "SET_PAYMENT_PROCESSORS": {
      const { paymentProcessors } = action
      return {
        ...state,
        paymentProcessors,
      }
    }
    default:
      return state
  }
}
