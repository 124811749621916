import data from '../data/product.json'

let editProduct = {}

if (process.env.LIVE_MODE) {
  editProduct = data
}

export default {
  isModalOpen: false,
  modalContent: undefined,
  user: {
    isLoggedIn: false,
  },
  products: [],
  paymentProcessors: [],
  editProduct,
  isLoading: false,
  theme: {
    palette: {
      primary: {
        main: "#19857b",
      }
    }
  }
}
